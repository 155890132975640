import React, { Component } from "react";
import { MDBIcon } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }
  setActive = () => {
    this.setState({
      active: true
    });
  }
  removeActive = () => {
    this.setState({
      active: false
    });
  }
  getActiveClassName = () => (
    `${this.state.active ? 'active' : ''}`
  )

  handleDatePickerChange = () => value => {
    this.setState({
      date: value
    });
    // workaround to update parent props
    this.props.getValue(value);
  };

  render() {
    return (
      <div className="md-form form-group">
        <MDBIcon far icon="calendar-alt" className={`prefix ${this.getActiveClassName()}`} />
        <DatePicker
          selected={this.state.date}
          onChange={this.handleDatePickerChange()}
          onFocus={this.setActive}
          onBlur={this.removeActive}
          placeholderText={this.props.label}
          className={`form-control datepicker ${this.getActiveClassName()}`} />
        <label
          onClick={this.setActive}
          className='active'>{this.props.label}</label>
      </div>
    );
  }
}

export default DatePickerInput;