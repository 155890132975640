import React, { Component } from "react";
import ReactDOM from "react-dom";
import { MDBBtn, MDBInput, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { initializeApp } from "firebase/app"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
// import * as firebaseui from 'firebaseui';
import CurrencyInput from './components/currencyInput';
import DatePickerInput from "./components/datepicker";
import SelectPage from './components/select';
import createPdf from './pdf';

import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";

import letterhead from './images/letterhead.jpg';


const firebaseConfig = {
  apiKey: "AIzaSyAvkDUzzwsS7VozODiG0_t66DMkQEF7kX0",
  authDomain: "shomrim-receipts.firebaseapp.com",
  databaseURL: "https://shomrim-receipts.firebaseio.com",
  projectId: "shomrim-receipts",
  storageBucket: "shomrim-receipts.appspot.com",
  messagingSenderId: "366233452422",
  appId: "1:366233452422:web:6375dcdd4092a24948b607",
  measurementId: "G-TY46F7YHZX"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getDatabase();

if (window.location.hostname === "localhost") {
  console.log('in localhost setup');
  connectFunctionsEmulator(functions, "localhost", 5001);
  // functions.useEmulator("localhost", 5001);
  connectDatabaseEmulator(db, "localhost", 9000);
  // db.useEmulator("localhost", 9000);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // TODO: add required validation for name and amount]
  handleMdbInputChange = inputName => value => {
    const nextValue = value;
    this.setState({
      [inputName]: nextValue
    });
  };

  // mimicking mdb functionality for custom components
  handleInputChange = inputName => event => {
    const nextValue = event.target.value;
    this.setState({
      [inputName]: nextValue
    });
  };

  submit = () => {
    const date = this.state.date ? this.state.date : new Date();
    const formattedDate = date.toLocaleDateString();
    this.setState({formattedDate});

    const generateReceipt = httpsCallable(functions, 'generateReceipt');
    console.log('About to generate receipt', this.state)
    generateReceipt({...this.state, formattedDate})
    .then(({ data }) => {
      this.setState({
        counter: data
      });
      console.log('Receipt counter generated', this.state.counter)
    })
    .catch(err => {
      console.error('error in generate receipt', err);
    })
    .finally(()=> this.createPdf())
  }

  createPdf = () => {
    console.log('About to create pdf')
    // TODO: display loader
    try {
      createPdf(this.state);
    } catch (err) {
      // TODO: display error message to user
      console.err('err in create pdf', err)
    }
  }

  render() {
    return (
      <React.Fragment>
        <MDBContainer>
          <img src={letterhead} className="img-fluid" alt="Shomrim logo" />
          <MDBRow>
            <MDBCol md="12" className="mb-r">
              <div id="form-inputs" className="form-inputs">
                <Form
                  handleMdbInputChange={this.handleMdbInputChange}
                  handleInputChange={this.handleInputChange}
                  handleDatePickerChange={this.handleDatePickerChange}
                />
                <MDBRow className="mb-4">
                  <MDBCol xl="3" md="6" className="mx-auto text-center">
                  <MDBBtn color="info" rounded onClick={this.submit}>
                      Create Receipt
                  </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

class Form extends Component {
  render() {
    return (
      <form className="mx-3 grey-text" id="receipt-form">
        <DatePickerInput
          name="date"
          label="Date (optional)"
          group
          getValue={this.props.handleMdbInputChange("date")}
        />
        <MDBInput
          name="name"
          label="Name"
          icon="user"
          hint="Full Name or Company Name"
          group
          type="text"
          getValue={this.props.handleMdbInputChange("name")}
        />
        <MDBInput
          name="address"
          label="Street Address"
          icon="address-card"
          hint="Street Address"
          group
          type="text"
          getValue={this.props.handleMdbInputChange("address")}
        />
        <MDBInput
          name="city"
          label="City"
          icon="address-card"
          hint="City"
          group
          type="text"
          getValue={this.props.handleMdbInputChange("city")}
        />
        <SelectPage
          name="state"
          label="State"
          group
          getValue={this.props.handleInputChange("state")} />
        { 
          // TODO: add autofill for city/state
        }
        <MDBInput
          name="zip"
          label="Zip Code"
          icon="address-card"
          hint="Zip Code"
          group
          type="text"
          getValue={this.props.handleMdbInputChange("zip")}
        />
        <CurrencyInput
          name="amount"
          label="Amount"
          hint="Amount"
          hasAmount={this.props.amount}
          group
          getValue={this.props.handleInputChange("amount")} />
        <MDBInput
          name="number"
          label="Receipt Number Override"
          icon="receipt"
          hint="Receipt Number (optional)"
          group
          type="number"
          getValue={this.props.handleMdbInputChange("number")}
        />
      </form>
    )
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
