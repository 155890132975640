const message = `By contributing to the Shomrim, you have taken the important step of protecting the safety of your family, your friends, your neighbors, and more. 

For over 30 years, the Shomrim have devoted countless hours to patrol and safeguard the community on a 24 hour basis, on their own time and their own vehicles. During this time, our volunteers have been pivotal in apprehending scores of perpetrators for a variety of crimes, saving our community from significant financial, emotional, and life-and-death anguish.

In addition to calls of criminal nature, Shomrim has also extended themselves to assist with missing persons and emergency house and car lockouts. Many lost children, Alzheimer's patients, and special needs individuals have been found and safely recovered thanks to their efforts.

Shomrim is proactive in disaster relief efforts, manning the community during natural disasters to mitigate risk and provide safety and protection for all. The organization has established and nurtured decade-long partnerships with the NYPD and community organizations across board; proactively spreading community outreach, awareness, and prevention above all.

In zechus of your contribution, may Hashem grant you and your family many years of safety, wellbeing and Yiddish nachas for all.`

export default message;
