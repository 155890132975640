import React, { Component } from "react";
import { MDBIcon } from "mdbreact";

import states from '../data/states';

class SelectPage extends Component {
    statesMapper = () => {
        const options = states.map(s => {
            const value = s.abbreviation;
            const label = s.name;
            return <option key={value} value={value}>{label}</option>
        })
        return [<option key="state">State</option>, ...options];
    }


    render() {
        const stateOptions = this.statesMapper();
        return (
            <div className="md-form form-group">
                <MDBIcon icon="address-card" className="prefix"/>
                <select onChange={this.props.getValue} className="browser-default custom-select">
                    {stateOptions}
                </select>
                <label
                    onClick={this.setActive}
                    className="active">{this.props.label}</label>
            </div>
        );
    }
}

export default SelectPage;