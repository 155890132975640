import React, { Component } from "react";
import { MDBIcon } from "mdbreact";
import CurrencyFormat from 'react-currency-format';


class CurrencyInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  setActive = () => {
    this.setState({
      active: true
    });
  }
  removeActive = () => {
    this.setState({
      active: false
    });
  }
  getActiveClassName = () => (
    `${this.state.active ? 'active' : ''}`
  )

  render() {
    return (
      <div className="md-form form-group">
        <MDBIcon icon="dollar-sign" className={`prefix ${this.getActiveClassName()}`} />
        <CurrencyFormat
          placeholder={this.props.hint}
          thousandSeparator={true}
          prefix={'$'}
          onChange={this.props.getValue}
          onFocus={this.setActive}
          onBlur={this.removeActive}
          className={`form-control ${this.getActiveClassName()}`} />
        <label
          onClick={this.setActive}
          className="active">{this.props.label}</label>
      </div>
    );
  }
}

export default CurrencyInput;