import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import letterhead from './images/letterhead-base64';
import officialReceipt from './images/receipt-base64';
import message from './data/letterMessage';

// TODO: name and amount should be required
// counter should not need default. counter logic is duplicated, see TODO comment in src/index
export default ({
    name = '',
    address = '',
    city = '',
    state = '',
    zip = '',
    amount = '',
    formattedDate = '',
    number = '',
    counter = 1327 // TODO: currently the default wont get applied if receipt num is specified in form
}) => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    
    const receiptCounter = number ? number : counter

    const formatCityAddress = () => {
        if (city && state) {
            return `${city}, ${state} ${zip}`;
        }
        if (city && zip) {
            return `${city}, ${zip}`
        }
        return city || '';
    }

    const documentDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        title: 'Boro Park Shomrim Donation Receipt',
        styles: {
            address: {
                fontSize: 10
            },
        },
        content: [
            {
                image: `${letterhead}`,
                width: 500
            },
            {
                text: `${formattedDate}`,
                alignment: 'right',
                style: 'address'
            },
            {
                text: `${name}`,
                alignment: 'left',
                style: 'address'
            },
            {
                text: `${address}`,
                style: 'address'
            },
            {
                text: `${formatCityAddress()}`,
                style: 'address'
            },
            '\n',
            {
                text: 'Thank you for your generous donation!',
                fontSize: 15,
                alignment: 'center',
                bold: true
            },
            {
                text: `${name ? `Dear ${name},` : ''}`,
                fontSize: 12
            },
            '\n',
            {
                text: `${message}`,
                fontSize: 12
            },
            '\n',
            {
                text: `Thank you and tizku l'mitzvahs.`,
                alignment: 'right',
                fontSize: 12
            },
            {
                text: `\n`
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 5,
                        x2: 535,
                        y2: 5,
                        dash: { length: 5 },
                        lineWidth: 0.5,
                    },
                ]
            },
            '\n',
            '\n',
            {
                table: {
                    heights: 40,
                    widths: ['auto', 75, 200, 50, 10, '*'],
                    body: [
                        [
                            {
                                image: `${officialReceipt}`,
                                width: 75,
                                margin: [0, 10, 0, 0],
                                border: [true, true, false, false],
                            },
                            {
                                image: `${letterhead}`,
                                width: 350,
                                rowSpan: 2,
                                colSpan: 4,
                                alignment: 'center',
                                border: [false, true, false, false],
                            },
                            '',
                            '',
                            '',
                            {
                                text: `No: ${new Date(formattedDate).getFullYear()}-${receiptCounter}`,
                                border: [false, true, true, false],
                                margin: [0, 10, 0, 0],
                            }
                        ],
                        [
                            {
                                text: '',
                                border: [true, false, false, false],
                            },
                            '',
                            '',
                            '',
                            '',
                            {
                                text: '',
                                border: [false, false, true, false],
                            },
                        ],
                        [
                            {
                                text:
                                    `${name}
                                    ${address}
                                    ${formatCityAddress()}`,
                                fontSize: 10,
                                colSpan: 2,
                                border: [true, false, false, false],
                            },
                            '',
                            {
                                text: [
                                    {
                                        text: `AMOUNT: ${amount}`,
                                        fontSize: 15,
                                        bold: true
                                    },
                                    '\n',
                                    {
                                        text: `Thank you for your generous donation`,
                                    }
                                ],
                                fillColor: '#eeeeee',
                                alignment: 'center',
                            },
                            {
                                text: [
                                    '\n',
                                    {
                                        text: 'Federal ID: 83-3705815',
                                        margin: [0, 0, 0, 10]
                                    },
                                    '\n',
                                    {
                                        text: 'Tax Exempt 501(c)(3)'
                                    }
                                ],
                                fontSize: 10,
                                italics: true,
                                colSpan: 3,
                                alignment: 'right',
                                border: [false, false, true, false],
                            },
                            '',
                            ''

                        ],
                        [
                            {
                                text: 'All contributions are tax deductible',
                                fontSize: 8,
                                colSpan: 3,
                                margin: [0, 30, 0, 0],
                                border: [true, false, false, true],
                            },
                            '',
                            '',
                            {
                                text: 'No goods or services were rendered in exchange for this contribution',
                                colSpan: 3,
                                fontSize: 8,
                                alignment: 'right',
                                noWrap: true,
                                margin: [0, 30, 0, 0],
                                border: [false, false, true, true],
                            },
                            '',
                            ''
                        ]
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            }
        ]
    };

    pdfMake.createPdf(documentDefinition).download();
}